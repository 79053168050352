<template>
  <div>
    <div class="infotDetai">
      <div class="content" v-html="dataObj.content" />
    </div>
  </div>
</template>

<script>
import { template } from '@/api/public'
import { setDocumentTitle } from '@/utils/domUtil'

export default {
  data() {
    return {
      dataObj: {},
      id: '',
      code: ''
    }
  },
  created() {
    this.code = this.$route.query.code
    this.getTemplate()
  },
  methods: {
    getTemplate() {
      template(this.code)
        .then(res => {
          console.log(res)
          const { success, result } = res
          if (success) {
            this.dataObj = result
            setDocumentTitle(result.title)
          }
        })
        .catch(err => {
          this.requestFailed(err)
        })
    },
    // 错误请求
    requestFailed(err) {
      console.error({
        message: '错误',
        description: ((err.response || {}).data || {}).message || '请求出现错误，请稍后再试',
        duration: 4
      })
    }
  }
}
</script>

<style lang="less" scoped>
.infotDetai {
  margin-top: 10px;
  padding: 0 15px;
  .title {
    color: #333;
    font-size: 16px;
    margin-bottom: 16px;
    font-weight: bold;
    text-align: center;
  }
  .content {
    margin-bottom: 28px;
  }
  ::v-deep img{
    width: auto;
    max-width: 100%;
    height: auto;
  }
}
</style>
