var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "infotDetai" }, [
      _c("div", {
        staticClass: "content",
        domProps: { innerHTML: _vm._s(_vm.dataObj.content) }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }